import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { CAUSAL_CHAINS, CAUSAL_CHAINS_TERMINATING_EVENTS } from 'gql/causalChain';
import setPageTitle from 'util/titles';
import CausalChainsList from './CausalChainsList';

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: 'Poppins',
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 40
  }
});

const DashboardCausal = () => {
  const classes = useStyles();

  const [justifyData, setJustifyData] = useState([]);
  const [terminatingEvents, setTerminatingEvents] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [sortField, setSortField] = useState('revenue');
  const [searchInputValue, setSearchInputValue] = useState('');

  const { data, loading, refetch } = useQuery(CAUSAL_CHAINS, {
    variables: { query: searchInputValue, sortField, terminatingEvents: selectedEventTypes },
    fetchPolicy: 'network-only'
  });

  const { data: terminatingEventsData } = useQuery(CAUSAL_CHAINS_TERMINATING_EVENTS, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setPageTitle('Causal');
  }, []);

  useEffect(() => {
    if (terminatingEventsData?.causalChainsTerminatingEvents?.length) {
      const initialEvents = terminatingEventsData.causalChainsTerminatingEvents;

      setTerminatingEvents(initialEvents);
      setSelectedEventTypes(initialEvents);
    } else {
      setTerminatingEvents([]);
      setSelectedEventTypes([]);
    }
  }, [terminatingEventsData]);

  useEffect(() => {
    if (!loading) {
      if (data?.causalChains?.length) {
        setJustifyData(data.causalChains);
      } else {
        setJustifyData([]);
      }
    }
  }, [data, loading]);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <CausalChainsList
        setJustifyData={setJustifyData}
        justifyData={justifyData}
        terminatingEvents={terminatingEvents}
        selectedEventTypes={selectedEventTypes}
        setSelectedEventTypes={setSelectedEventTypes}
        loading={loading}
        refetch={refetch}
        sort={sortField}
        setSort={setSortField}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />
    </Grid>
  );
};

DashboardCausal.propTypes = {};

export default DashboardCausal;
