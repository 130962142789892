import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { ORGANIZATION_SETTINGS } from 'gql/organization';
import { CAUSAL_CHAINS_SUMMARY } from 'gql/causalChainsSummary';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import { LogoColored } from 'util/assets';
import { useReactToPrint } from 'ReactToPrint';

import { Print } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { motion } from 'framer-motion';
import StayTuned from './StayTuned';

import DateView from './DateView';
import SummaryOfSummaries from './SummaryOfSummaries';

const setPrintStyling = () => {
  return `@page { 
    margin: 50px 40px 50px 40px !important; 
  }
  .printTitle {
    @media print {
      font-size: 20px;
    }
  }
  .printRightSide {
    @media print {
      position: relative;
      bottom: 60px;
    }
  }
  .printLogo {
    display: none;

    @media print {
      display: block;
    }
  }
  .printBtn {
    display: block;

    @media print {
      display: none;
    }
  }
  `;
};

const useStyles = makeStyles(() => ({
  topMatter: {
    justifyContent: 'space-between'
  },
  summaryContainer: {
    overflow: 'hidden'
  },
  title: {
    lineHeight: '1.2',
    marginBottom: '40px',
    marginTop: '0px'
  },
  rightSide: {
    float: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  printButton: {
    color: '#000000'
  }
}));

const InsightsTabPanel = props => {
  const { timeSlice } = props;
  const classes = useStyles();

  // react-to-print use for printing the modal
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });

  const [startDate, setStartDate] = useState(moment.utc());
  const [endDate, setEndDate] = useState(moment.utc());

  const { loading, error, data } = useQuery(CAUSAL_CHAINS_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: {
      timeSlice,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString()
    },
    skip: startDate == null && endDate == null
  });

  const { data: queryData } = useQuery(ORGANIZATION_SETTINGS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (timeSlice === 'Month') {
      setStartDate(moment.utc().startOf('M'));
      setEndDate(moment.utc().endOf('M'));
    }

    if (timeSlice === 'Quarter' && queryData) {
      // calculate the date range by offset
      const offset = queryData.getCurrentOrganization.year_start - 1 || 0;
      const startOffset = moment
        .utc()
        .startOf('Q')
        .add(offset % 3, 'M');
      const endOffset = startOffset
        .clone()
        .endOf('Q')
        .add(offset % 3, 'M');

      // if current date falls outside the offset range then determine start and end adjusted by offset
      while (moment.utc().isBefore(startOffset)) {
        startOffset.subtract(3, 'M');
        endOffset.subtract(3, 'M');
      }

      setStartDate(startOffset.startOf('M'));
      setEndDate(endOffset.endOf('M'));
    }
  }, [timeSlice, queryData]);

  const handlePreviousTimeSlice = () => {
    setStartDate(prevStartDate =>
      moment
        .utc(prevStartDate)
        .subtract(1, timeSlice)
        .startOf('M')
    );
    setEndDate(prevEndDate =>
      moment
        .utc(prevEndDate)
        .subtract(1, timeSlice)
        .endOf('M')
    );
  };

  const handleNextTimeSlice = () => {
    setStartDate(prevStartDate =>
      moment
        .utc(prevStartDate)
        .add(1, timeSlice)
        .startOf('M')
    );
    setEndDate(prevEndDate =>
      moment
        .utc(prevEndDate)
        .add(1, timeSlice)
        .endOf('M')
    );
  };

  if (loading) {
    return <AlbLoading />;
  }

  if (error) {
    return <AlbError error={error} toast={false} />;
  }

  return (
    <div ref={printRef}>
      {!loading && data?.causalChainsSummary?.summary && (
        <>
          <style>{setPrintStyling()}</style>
          <Grid
            className="printLogo"
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <img src={LogoColored} alt="Alembic Logo" height="40px" />
          </Grid>
        </>
      )}
      <Grid container className={classes.topMatter}>
        <h1 className={`${classes.title} printTitle`}>{timeSlice}ly Intelligence Briefing</h1>
        <Grid className={`${classes.rightSide} printRightSide`}>
          <DateView
            timeSlice={timeSlice}
            data={data}
            dateRange={{
              range: `${moment.utc(startDate).format('MMM D')} - ${moment
                .utc(endDate)
                .format('MMM D')}`,
              year: moment.utc(endDate).format('YYYY')
            }}
            handlePreviousTimeSlice={handlePreviousTimeSlice}
            handleNextTimeSlice={handleNextTimeSlice}
            disableNext={moment.utc(endDate).isSameOrAfter(moment.utc())}
          />
          {!loading && data?.causalChainsSummary?.summary && (
            <IconButton className={`${classes.printButton} printBtn`} onClick={reactToPrintFn}>
              <Print />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!loading && (
        <>
          <div className={classes.summaryContainer}>
            <motion.div
              layout
              initial={{ opacity: 0, y: 900 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -900 }}
              transition={{
                opacity: { duration: 1.5 },
                y: { duration: 0.75 }
              }}
            >
              {data?.causalChainsSummary?.summary && (
                <SummaryOfSummaries summary={data?.causalChainsSummary?.summary} />
              )}
              {!data?.causalChainsSummary?.summary && <StayTuned />}
            </motion.div>
          </div>
        </>
      )}
    </div>
  );
};

InsightsTabPanel.propTypes = {
  timeSlice: PropTypes.string.isRequired
};

export default InsightsTabPanel;
