/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { ExpandMore, MoreHoriz } from '@material-ui/icons';
import AlembicTitleChip from 'components/AlembicTitleChip';
import { getDrawerHeaderTitle, getReadableDetectedEventTitle } from 'util/detectedEvents';
import getValueAbbreviation from 'util/getValueAbbreviation';
import CausalTimeline from './CausalTimeline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px'
  },
  headingNumber: {
    fontWeight: 'bold'
  },
  chainContainer: {
    overflow: 'hidden'
  },
  accordion: {
    marginBottom: '30px'
  },
  details: {
    justifyContent: 'center'
  },
  footerButton: {
    margin: '1em 0',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  chips: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '-10px',

    '& > *': {
      marginRight: '10px',
      marginBottom: '10px'
    }
  },
  ellipsis: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '25px',
    width: '25px',
    marginRight: '-35px'
  }
}));

const chipStyle = {
  background: '#EAEAEA',
  padding: '4px 10px',
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center'
};

/**
 * @method
 * @summary This component renders the accordion
 * @name CausalAccordion
 * @return {Object} - React JSX
 */

const CausalAccordion = props => {
  const { justifyData, expandJustify } = props;
  const classes = useStyles();

  const loadAfter = 50;
  const [lastRow, setLastRow] = useState(loadAfter);
  const [expandedChipRows, setExpandedChipRows] = useState({});

  useEffect(() => {
    const tempLookup = {};

    // create lookup table for causal chain ids to display expand chips button
    justifyData.forEach(causalChain => {
      const json = causalChain?.raw_chain_data?.causal_chain;
      if (json) {
        const chipCount = Object.values(json).flatMap(Object.entries);

        // null = hide button
        // false = collapsed
        // true = expanded
        tempLookup[causalChain.id] = chipCount.length <= 3 ? null : false;
      }
    });

    setExpandedChipRows(tempLookup);
  }, [justifyData]);

  const countNodes = causalChain => {
    const nodeCount = Object.keys(causalChain).length;
    return nodeCount > 1 ? (
      <span style={{ fontWeight: 700 }}>{`${nodeCount} Nodes`}</span>
    ) : (
      'Single Node Chain'
    );
  };

  const scrollObserver = (entries, observer) => {
    entries.forEach(en => {
      if (en.intersectionRatio > 0) {
        setLastRow(prevState => prevState + loadAfter);
        observer.unobserve(en.target);
      }
    });
  };

  const bottomBoundaryRef = useCallback(node => {
    if (node !== null) {
      const observer = new IntersectionObserver(scrollObserver);
      observer.observe(node);
    }
  }, []);

  const expandChips = (e, id) => {
    e.stopPropagation();
    setExpandedChipRows(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <div className={classes.root}>
      {justifyData.slice(0, lastRow).map((causalChain, i) => {
        const json = causalChain?.raw_chain_data?.causal_chain;

        return (
          <div className={classes.chainContainer} key={causalChain.id}>
            <AnimatePresence>
              <motion.div
                key={causalChain.id}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{
                  duration: 0.1,
                  delay: i * 0.1 // Add 0.1 second delay for each subsequent accordion
                }}
              >
                <Accordion
                  className={classes.accordion}
                  ref={i + 1 === lastRow ? bottomBoundaryRef : null}
                >
                  <AccordionSummary
                    style={{ alignItems: 'flex-start' }}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container className={classes.heading} direction="column">
                      <div className={classes.title}>
                        <span>
                          <span className={classes.headingNumber}>{i + 1}.</span>{' '}
                          {causalChain.title}
                        </span>
                        {json && <span>{countNodes(json)}</span>}
                      </div>
                      <span className={classes.chips}>
                        {json &&
                          // parse through chains
                          Object.values(json)
                            // flatten through 'nodes'
                            .flatMap(value => Object.entries(value))
                            // default display 3 until expanded
                            .slice(0, expandedChipRows[causalChain.id] ? undefined : 3)
                            // extract event types and sum value
                            .map(([eventType, events], index) => {
                              const sum = events.reduce((acc, curr) => {
                                // eslint-disable-next-line no-param-reassign
                                acc += curr.mag;
                                return acc;
                              }, 0);

                              const body = getDrawerHeaderTitle({
                                event: { type: getReadableDetectedEventTitle(eventType) }
                              });

                              return (
                                <AlembicTitleChip
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index + causalChain.id + eventType}
                                  title={getValueAbbreviation(sum, false, true)}
                                  body={body}
                                  customBodyStyle={chipStyle}
                                />
                              );
                            })}

                        {Object.prototype.hasOwnProperty.call(expandedChipRows, causalChain.id) &&
                          expandedChipRows[causalChain.id] !== null && (
                            <IconButton
                              className={classes.ellipsis}
                              size="small"
                              onClick={e => expandChips(e, causalChain.id)}
                            >
                              <MoreHoriz fontSize="inherit" color="inherit" />
                            </IconButton>
                          )}
                      </span>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Typography>
                      <CausalTimeline causalChainSummary={causalChain.summary} />
                      <Grid container justifyContent="center">
                        <Button
                          className={classes.footerButton}
                          variant="contained"
                          onClick={e => expandJustify(e, causalChain)}
                        >
                          JUSTIFY
                        </Button>
                      </Grid>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </motion.div>
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

CausalAccordion.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  expandJustify: PropTypes.func.isRequired
};

CausalAccordion.defaultProps = {
  justifyData: null
};

export default CausalAccordion;
